import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components";


const Sidebarholder = styled.div`
h4{
  font-size: 1.5rem;
  position: relative;
}
a{
	color: #333;
}

`;



const Sidebar = (props) => {




  return (
    <Sidebarholder>
<h4>Reform Pilates Cork</h4><hr/>

<div className="box posrel">
<address>Unit 1 Celtic Business Park, Monahan Road, Cork</address>
<br/>
<p>E: <a href="mailto:reformpilatescork@gmail.com">reformpilatescork@gmail.com</a></p>
<p>T: <a href="tel:353879380484">087 938 0484</a></p>
</div>

    <Link to="/booknow" className="">
<div className="box posrel invertbox">
    Book Now
</div>
</Link>
<br/>
<div className="box posrel daysofweek">
<p className="dayofweek">Monday:</p>
<p>9:30 AM - 10:30 AM</p>
<p>6:30 PM - 9:30 PM</p>
<p className="dayofweek">Tuesday:</p>
<p>9:30 AM - 10:30 AM</p>
<p>6:30 PM - 9:30 PM</p>
<p className="dayofweek">Wednesday:</p>
<p>9:30 AM - 11:30 AM</p>
<p>6:30 PM - 9:30 PM</p>
<p className="dayofweek">Thursday:</p>
<p>9:30 AM - 11:30 AM</p>
<p>6:30 PM - 8:30 PM</p>
<p className="dayofweek">Friday:</p>
<p>9:30 AM - 10:30 AM</p>
<p className="dayofweek">Saturday:</p>
<p>9:00 AM - 11:00 AM</p>
<p className="dayofweek">Sunday:</p>
<p>CLOSED</p>
</div>
    </Sidebarholder>
    );
}

export default Sidebar