import * as React from "react"
import styled from "styled-components";
import { useInView } from 'react-intersection-observer';

const Sectionaholder = styled.div`
.internal-page-banner{
	background-size: cover;
    background-position: 0% 60%;
	background-repeat: no-repeat;
	margin-bottom: 2em;

	.container{
		opacity: 0;
		transition: all .75s ease;
		transform: translateY(10px);
	}
	.container.fadedIn{
		opacity: 1;
		transition: all .75s ease;
		transform: translateY(0px);
	}

	.underbox{
			position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: #000;
		opacity: 0.7;
	}
	h1{
		color: #fff;
		font-size: 3rem;
		font-weight: 600;
		display: inline-block;
		padding: 3px 10px;
		position: relative;
		margin: 0;
		line-height: 1em;
	}
	h2{
		color: #fff;
		font-size: 1.2rem;
		font-weight: 300;
		position: relative;
		margin: 0;
		line-height: 1em;
	}

	@media screen and (min-width:768px) {
		height: 60vh;
	}
	@media screen and (max-width:767px) {
		height: 28vh;
	}

             @media screen and (max-width:767px) {
              .mobflexer{
              	height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
              }  
             }

}
`;

const Internalbanner = (props) => {
	const {title} = props
	const {subtitle} = props
	const {bannerpic} = props
	const { ref, inView } = useInView();

	return (
		<Sectionaholder ref={ref}>
		<section className="internal-page-banner" style={{  
			backgroundImage: "url(" + bannerpic + ")",
		}}>

		<div className={inView ? 'container hundheight fadedIn':'container hundheight'}>
		<div className="columns is-marginless is-centered level moblevel hundheight">
		<div className="column is-8 has-text-centered posrel mobflexer">
		<div className="underbox"></div>
		<h1>{title}</h1>
		<h2>{subtitle}</h2>
		</div>
		</div>
		</div>
		</section>
		</Sectionaholder>
		);
	}

	export default Internalbanner