import * as React from "react"
import styled from "styled-components";
import { useInView } from 'react-intersection-observer';
import Shape1 from "../images/shape1.svg";
import Shape2 from "../images/shape2.svg";
import Shape8 from "../images/shape8.svg";
import Shape9 from "../images/shape9.svg";
import Shape10 from "../images/shape10.svg";
import Finduspic from "../images/findus.svg";
const Sectionaholder = styled.div`
.section-b{
  opacity: 0;
      transition: all .3s ease;
}
.section-b.fadedIn{
  opacity: 1;
    transition: all .3s ease;
}
.shapes_a{
background-image: url('${Shape9}'); margin-top:1170px; margin-left:2025px; 
}
.shapes_b{
background-image: url('${Shape2}'); margin-top:222px; margin-left:408px; 
}
.shapes_c{
background-image: url('${Shape10}'); margin-top:880px; margin-left:1820px; 
}
.shapes_d{
background-image: url('${Shape8}'); margin-top:560px; margin-left:1264px; 
}
.shapes_e{
	background-image: url('${Shape1}'); margin-top:55px; margin-left:205px; 
}




.sveeghold{
	background-image: url('${Finduspic}');
}
`;

const Findus = () => {

  const { ref, inView } = useInView();
  return (
  	<Sectionaholder ref={ref}>
  	<div className="hideonmobile">
  	<section className={inView ? 'section-b fadedIn':'section-b'}>
  	<div className="shapeshifter">
  	<div className="shapes shapes_a"></div>
  	<div className="shapes shapes_b"></div>
  	<div className="shapes shapes_c"></div>
  	<div className="shapes shapes_d"></div>
  	<div className="shapes shapes_e"></div>
  	</div>	
  	<div className="containerfullwidth"> 
  	<div className="columns is-marginless">

  	<div className="column is-1 findushold">
  	<div className="sveeghold"></div>
  	</div>

  	<div className="column is-10 is-offset-1"> 
  	<div className="internalpadding">
  	<address>
  	<span className="yellowtext largeaddresstext">Unit 1 Celtic Business Park, Monahan Road, Cork</span>
  	<span className="lowertext loweraddresstext">Join us in our brand new studio!<br /></span>
  	</address>
  	</div>
  	</div>
  	</div>
  	</div>
  	</section>
  	</div>
  	</Sectionaholder>
  );
}

export default Findus