import * as React from "react"
// import { Link } from "gatsby"
import styled from "styled-components";
import { useInView } from 'react-intersection-observer';


const Sectionaholder = styled.div`

.imagetrio{
	opacity: 0;
	transition: all 1s ease;
		padding: 3em;
		background: #000;
	}
.imagetrio.fadedIn{
	opacity: 1;
		transition: all 1s ease;
}

`;



const Imagetrio = (props) => {
	const {imagea}= props
	const {imageb}= props
	const {imagec}= props

const { ref, inView } = useInView();

	return (
		<Sectionaholder ref={ref}>
<section className={inView ? 'imagetrio fadedIn':'imagetrio'}>
		<div className="columns is-marginless">
		<div className="column is-one-third">
		<img src={imagea} alt="Reform Pilates Cork" />
		</div>
		<div className="column is-one-third">
<img src={imageb} alt="Reform Pilates Cork" />
		</div>
		<div className="column is-one-third">
<img src={imagec} alt="Reform Pilates Cork" />
		</div>
      </div>

</section>
		</Sectionaholder>
		);
}

export default Imagetrio