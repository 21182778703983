import * as React from "react"
import {graphql } from "gatsby"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Navbar from "../../components/navbar"
import Reformfooter from "../../components/footer"
// import Bookaclass from "../../components/bookaclass"
import Internalbanner from "../../components/internalbanner"
// import Maininternalcopy from "../../components/maininternalcopy"
import Imagetrio from "../../components/imagetrio"
// import Sectiona from "../../homepage/sectiona"
// import Sectionb from "../../homepage/sectionb"
import Findus from "../../homepage/findus"
import Homeswiper from "../../homepage/homeswiper"
// import Swipage from "../../components/swiper"
import Sidebar from "../../components/sidebar"

import Yogaback from "../../images/yoga3.jpg"
import YogaPic1 from "../../images/studio/beginners-1.jpg"
import YogaPic2 from "../../images/studio/beginners-2.jpg"
import YogaPic3 from "../../images/studio/beginners-3.jpg"
import YogaPic4 from "../../images/studio/beginners-4.jpg"


const Beginner_reformer = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Beginner Reformer`


  return (
    <Layout location={location} title={siteTitle}>
    <Seo title="Beginner Reformer Pilates" description="Beginner Pilates classes at Reform Pilates Cork"/>
    <Navbar/>
    <Internalbanner title="Beginners Reformer" subtitle="A 5-week Beginners course" bannerpic={YogaPic3}/>

    <section className="internal-page-main gencont">
    <div className="container">
    <div className="columns is-marginless is-centered">
    <div className="column is-8">
        <h3>Beginners Reformer</h3><hr/>
    <p class="toningtag">
    Perfect for: <span className="tag">core strength</span><span className="tag">improving tone and posture</span><span className="tag">newcomers to pilates</span>
    </p>
      <p class="blacksignature">The best possible start in your Reformer journey</p>
    <p>To give clients the best possible start in their Reformer journey, Sally and her team regularly host a 5-week Beginners course.</p>
    <p>This approach ensures that you get the time and opportunity to learn the basics of this style of Pilates at your own pace, within a smaller group (max 8), and at the same time as other newbies! Like driving, it can take a session or two to grasp the basics but focusing on the principles and core exercises initially will give you the confidence to continue your journey in our open and welcoming community. After all, we all had to start somewhere! To sign up, <Link to="/#contact-reform-pilates-cork" >get in touch</Link> and we’ll chat you through everything you need to know.
    </p>
    <div className="columns is-marginless is-centered">
    <div className="column is-8 is-paddingless">
    <img src={YogaPic4} alt="Beginners Yoga Classes Cork Reform Pilates" className="midyogapic"/>
    </div>
    </div>
    </div>
    <div className="column is-3 is-offset-1">
    <Sidebar/>
    </div>
    </div>
    </div>
    </section>

    <Imagetrio imagea={YogaPic1} imageb={YogaPic2} imagec={YogaPic3} />
    <Findus />
    <Homeswiper />
    <Reformfooter />
    </Layout>
    )
}

export default Beginner_reformer

export const pageQuery = graphql`
query {
  site {
    siteMetadata {
      title
    }
  }
  allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
    nodes {
      excerpt
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
      }
    }
  }
}
`
